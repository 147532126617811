import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  makeStyles,
  Box,
  Tooltip,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import Page from 'src/components/Page';
import DialogForms from 'src/views/commons/DialogForms';
import DataTable from 'src/views/commons/DataTable';
import { Edit, Email } from '@material-ui/icons';
import combosService from 'src/services/combosService';
import ordenService from 'src/services/ordenService';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import chipEstatusTable from 'src/views/commons/ChipEstatusTable';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import AltaOrden from './AltaOrden';
import OrdenesToolBar from './OrdenesToolBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  }
}));

const Ordenes = ({
  setComboOrdenEstatus,
  comboOrdenEstatus,
  comboLabelStatus,
  setComboLabelStatus,
  setComboHiperliga,
  comboHiperliga,
  setOpenBackDrop,
  setServerError,
  setOpenSnackError,
  rolUsuario,
  ...props
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [openAltaOrden, setOpenAltaOrden] = useState(false);
  const [openFechaCita, setOpenFechaCita] = useState(false);
  const [updateRowStatus, setUpdateRowStatus] = useState({});
  const [fechaCita, setFechaCita] = React.useState(null);
  const [openComentarios, setOpenComentarios] = useState(false);
  const [comentarios, setComentarios] = React.useState(null);
  const [rowsOrdenes, setRowsOrdenes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [comboClientes, setComboClientes] = useState([]);
  const [comboAnalistas, setComboAnalistas] = useState([]);
  const [comboGradoEscolar, setComboGradoEscolar] = useState([]);
  const [mapaUsuarios, setMapaUsuarios] = useState([]);
  const [comboUsuarios, setComboUsuarios] = useState([]);
  const [formData, setFormData] = useState({});
  const [idOrden, setIdOrden] = useState(0);
  const [actionButtonName, setActionbuttonName] = useState('');
  const formName = 'formOrden';
  const ordenRef = useRef();

  const getClickActionButton = () => {
    if (idOrden === 0) {
      return ordenRef.current.resetOrdenForm();
    }
    if (rolUsuario === 'ADMINISTRADOR') {
      return ordenRef.current.sendOrderStatusEmail();
    }
    return undefined;
  };

  const openAlta = (altaFlag, idOrdenAlta) => {
    if (idOrdenAlta === 0) {
      setActionbuttonName('LIMPIAR');
    } else if (idOrdenAlta !== 0 && rolUsuario === 'ADMINISTRADOR') {
      setActionbuttonName('NOTIFICAR');
    } else {
      setActionbuttonName(undefined);
    }
    setOpenAltaOrden(altaFlag);
  };

  const onClickEdit = (rowId) => {
    const modifyOrder = async (ordenId) => {
      setOpenBackDrop(true);
      await ordenService.getOrdenById(ordenId).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          setFormData(resp.data);
          openAlta(true, resp.data.id);
        }
      }).catch((err) => {
        console.log(err);
        setServerError(err.message);
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBackDrop(false);
      });
    };
    modifyOrder(rowId);
  };

  const saveOrderStatus = async (data) => {
    const { setOpenSnackSuccess, setServerMessage } = props;
    setOpenBackDrop(true);
    await ordenService.updateOrderStatus(data).then((resp) => {
      if (resp.data != null && resp.data.errorCode === 204) {
        setServerError(resp.data.mensaje);
        setOpenSnackError(true);
      } else {
        setUpdateRowStatus({});
        setFechaCita(undefined);
        setComentarios(undefined);
        setServerMessage('El estatus de la orden fue actualizado correctamente.');
        setOpenSnackSuccess(true);
      }
      return resp;
    }).catch((err) => {
      console.log(err);
      setServerError(err.message);
      setOpenSnackError(true);
    }).finally(() => {
      setOpenBackDrop(false);
    });
  };

  const onChangeStatus = (fieldUpdated) => {
    if (fieldUpdated.field === 'descOrdenEstatus' && fieldUpdated.value === 'CITA') {
      const rowSel = rowsOrdenes.find((rw) => rw.id === fieldUpdated.id);
      if (rowSel.fechaCita) {
        setFechaCita(moment(rowSel.fechaCita).format('YYYY-MM-DDTHH:mm'));
      } else {
        setFechaCita(moment(new Date()).format('YYYY-MM-DDTHH:mm'));
      }
      setOpenFechaCita(true);
      setUpdateRowStatus(fieldUpdated);
    } else if (fieldUpdated.field === 'descOrdenEstatus' && fieldUpdated.value === 'SUSPENDIDO') {
      const rowSel = rowsOrdenes.find((rw) => rw.id === fieldUpdated.id);
      if (rowSel.comentarios) {
        setComentarios(rowSel.comentarios);
      }
      setOpenComentarios(true);
      setUpdateRowStatus(fieldUpdated);
    } else {
      const updateStatus = {
        id: fieldUpdated.id,
        descOrdenEstatus: fieldUpdated.value,
      };
      saveOrderStatus(updateStatus);
    }
  };

  useEffect(() => {
    const getCombosAsync = async () => {
      setOpenBackDrop(true);
      await combosService.comboAnalistas().then((resp) => {
        setComboAnalistas(resp);
      }).catch((error) => {
        setOpenBackDrop(false);
        console.log(`'Error al cargar el combo de analistas: '${error}`);
      });
      await combosService.comboClientes().then((resp) => {
        setComboClientes(resp);
      }).catch((error) => {
        setOpenBackDrop(false);
        console.log(`'Error al cargar el combo de clientes: '${error}`);
      });
      if (comboOrdenEstatus === undefined || comboOrdenEstatus.length === 0) {
        await combosService.comboOrdenEstatus().then((resp) => {
          setComboOrdenEstatus(resp);
          const labelStatus = [];
          resp.forEach((status) => {
            labelStatus.push(status.label);
          });
          setComboLabelStatus(labelStatus);
        });
      }
      if (comboHiperliga === undefined || comboHiperliga === null || comboHiperliga.length === 0) {
        await combosService.comboLinkEstatusActivos().then((resp) => {
          setComboHiperliga(resp);
        });
      }
      const idDashOrden = window.localStorage.getItem('idOrden');
      if (idDashOrden) {
        window.localStorage.removeItem('idOrden');
        onClickEdit(idDashOrden);
      }
      setOpenBackDrop(false);
    };
    getCombosAsync();
  }, []);

  const columns = [
    {
      field: 'folio',
      headerName: 'Folio',
      maxWidth: 140,
      minWidth: 140,
      headerClassName: 'header-datagrid',
      renderCell: (params) => {
        const onClick = () => {
          onClickEdit(params.row.id);
        };
        return (
          <Tooltip title="Editar">
            <Chip
              color="primary"
              label={params.value}
              size="small"
              variant="default"
              onClick={onClick}
              deleteIcon={(
                <Edit fontSize="small" />
              )}
              onDelete={onClick}
            />
          </Tooltip>
        );
      }
    },
    {
      field: 'descOrdenEstatus',
      headerName: 'Estatus',
      minWidth: 140,
      flex: 1,
      editable: rolUsuario === 'ADMINISTRADOR',
      type: 'singleSelect',
      valueOptions: comboLabelStatus,
      renderCell: (params) => {
        return chipEstatusTable(params, true);
      }
    },
    {
      field: 'nombreUsuario',
      headerName: 'Usuario',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreCliente',
      headerName: 'Cliente',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombre',
      headerName: 'Investigado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'telefonoPrimario',
      headerName: 'Teléfono',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'fechaSolicitudStr',
      headerName: 'Solicitud',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'fechaCita',
      headerName: 'Cita',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (updateRowStatus && updateRowStatus.id === params.id && fechaCita) {
          params.value = fechaCita;
          params.row.fechaCita = fechaCita;
        }
        if (params.value === null || params.value === undefined) {
          params.value = '--';
        } else {
          params.value = moment(new Date(params.value)).format('YYYY-MM-DD HH:mm:ss');
        }
        return renderCellExpand(params);
      }
    },
    {
      field: 'descEstado',
      headerName: 'Estado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'consultar',
      headerName: 'Consultar',
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          onClickEdit(params.row.id);
        };
        return (
          <div>
            <Tooltip title="Editar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
                variant="outlined"
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Page
      className={classes.root}
      title="Búsqueda de Ordenes"
    >
      <Container maxWidth="lg">
        <OrdenesToolBar
          openAltaOrden={openAltaOrden}
          setOpenAltaOrden={openAlta}
          rowsOrdenes={rowsOrdenes}
          setRowsOrdenes={setRowsOrdenes}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setOpenBackDrop={setOpenBackDrop}
          rolUsuario={rolUsuario}
          comboOrdenEstatus={comboOrdenEstatus}
          comboAnalistas={comboAnalistas}
          {...props}
        />
        <Box mt={3} />
        <DataTable
          rows={rowsOrdenes}
          columns={columns}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          activateCheckBox={rolUsuario === 'ADMINISTRADOR'}
          onChangeStatus={onChangeStatus}
        />
        <DialogForms
          setOpenDialog={setOpenAltaOrden}
          openDialog={openAltaOrden}
          submitButtonName={idOrden === 0 ? 'GUARDAR' : 'ACTUALIZAR'}
          titulo={idOrden === 0 ? 'Alta de Orden' : 'Editar Orden'}
          formName={formName}
          actionButtonName={actionButtonName}
          handleRef={getClickActionButton}
          actionIcon={actionButtonName === 'NOTIFICAR' ? <Email /> : null}
          size="md"
        >
          <AltaOrden
            formName={formName}
            ref={ordenRef}
            idOrden={idOrden}
            setIdOrden={setIdOrden}
            formData={formData}
            setFormData={setFormData}
            rowsOrdenes={rowsOrdenes}
            comboClientes={comboClientes}
            comboAnalistas={comboAnalistas}
            comboOrdenEstatus={comboOrdenEstatus}
            comboHiperliga={comboHiperliga}
            comboGradoEscolar={comboGradoEscolar}
            setComboGradoEscolar={setComboGradoEscolar}
            mapaUsuarios={mapaUsuarios}
            setMapaUsuarios={setMapaUsuarios}
            comboUsuarios={comboUsuarios}
            setComboUsuarios={setComboUsuarios}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
            rolUsuario={rolUsuario}
            setOpenAltaOrden={setOpenAltaOrden}
            {...props}
          />
        </DialogForms>
        <Dialog open={openFechaCita} maxWidth="xs">
          <DialogTitle>Ingrese una Fecha de Cita</DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider
              locale={esLocale}
              utils={DateFnsUtils}
            >
              <TextField
                label="Fecha de Cita"
                type="datetime-local"
                value={fechaCita}
                onChange={(e) => {
                  setFechaCita(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                const updateStatus = {
                  id: updateRowStatus.id,
                  descOrdenEstatus: updateRowStatus.value,
                  fechaCita
                };
                const rowSel = rowsOrdenes.find((rw) => rw.id === updateRowStatus.id);
                rowSel.fechaCita = fechaCita;
                saveOrderStatus(updateStatus);
                setOpenFechaCita(false);
              }}
              color="primary"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openComentarios} maxWidth="md" fullWidth>
          <DialogTitle>Comentario por suspensión</DialogTitle>
          <DialogContent>
            <form
              id="formComments"
              onSubmit={
                handleSubmit((params) => {
                  const updateStatus = {
                    id: updateRowStatus.id,
                    descOrdenEstatus: updateRowStatus.value,
                    comentarios: params.comentarios
                  };
                  const rowSel = rowsOrdenes.find((rw) => rw.id === updateRowStatus.id);
                  rowSel.comentarios = params.comentarios;
                  saveOrderStatus(updateStatus);
                  setOpenComentarios(false);
                })
              }
            >
              <TextField
                label="Comentarios por suspensión *"
                value={comentarios}
                name="comentarios"
                fullWidth
                multiline
                minRows={3}
                onInput={genService.toUpperCase}
                error={errors.comentarios}
                helperText={errors.comentarios?.message}
                onChange={(e) => {
                  setComentarios(e.target.value);
                }}
                inputRef={register({
                  required: { value: true, message: 'Para guardar la orden es necesario agregar un comentario.' },
                })}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              form="formComments"
              color="primary"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

Ordenes.propTypes = {
  setComboOrdenEstatus: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  setComboLabelStatus: PropTypes.func,
  comboLabelStatus: PropTypes.array,
  setComboHiperliga: PropTypes.func,
  comboHiperliga: PropTypes.array,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  rolUsuario: PropTypes.string,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func
};
export default Ordenes;
