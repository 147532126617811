import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import GeneralMessages from 'src/views/commons/GeneralMessages';

const App = () => {
  const [isAuth, setIsAuth] = useState(true);
  const [nombreUsuario, setNombreUsuario] = useState(null);
  const [nombreCliente, setNombreCliente] = useState(window.localStorage.getItem('nombreCliente'));
  const [idCliente, setIdCliente] = useState(Number(window.localStorage.getItem('idCliente')));
  const [tipoCliente, setTipoCliente] = useState(window.localStorage.getItem('tipoCliente'));
  const [rolUsuario, setRolUsuario] = useState(null);
  const [resetPassword, setResetPassword] = useState(window.localStorage.getItem('reset'));
  const [comboTipoClientes, setComboTipoClientes] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [comboRoles, setComboRoles] = useState([]);
  const [serverError, setServerError] = React.useState('');
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = React.useState(false);
  const [serverMessage, setServerMessage] = React.useState();
  const [comboEstados, setComboEstados] = useState([]);
  const [comboMunicipios, setComboMunicipios] = useState([]);
  const [comboHiperliga, setComboHiperliga] = useState([]);
  const [mapaMunicipios, setMapaMunicipios] = useState([]);
  const [comboOrdenEstatus, setComboOrdenEstatus] = useState([]);
  const [comboLabelStatus, setComboLabelStatus] = useState([]);

  const handleCloseSnackError = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setServerError('');
    setOpenSnackError(false);
  };
  const handleCloseSnackSuccess = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setServerMessage('');
    setOpenSnackSuccess(false);
  };

  const routing = useRoutes(routes(
    isAuth,
    setIsAuth,
    nombreUsuario,
    setNombreUsuario,
    rolUsuario,
    setRolUsuario,
    setOpenBackDrop,
    comboRoles,
    setComboRoles,
    setServerError,
    setOpenSnackError,
    setOpenSnackSuccess,
    setServerMessage,
    comboEstados,
    comboMunicipios,
    setComboMunicipios,
    mapaMunicipios,
    setMapaMunicipios,
    comboTipoClientes,
    setComboTipoClientes,
    setComboOrdenEstatus,
    comboOrdenEstatus,
    setComboHiperliga,
    comboHiperliga,
    setComboEstados,
    resetPassword,
    setResetPassword,
    nombreCliente,
    setNombreCliente,
    idCliente,
    setIdCliente,
    tipoCliente,
    setTipoCliente,
    comboLabelStatus,
    setComboLabelStatus
  ));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <GeneralBackDrop open={openBackDrop} />
      <GeneralMessages
        open={openSnackError}
        handleCloseSnackError={handleCloseSnackError}
        serverError={serverError}
        openSnackError={openSnackError}
        handleCloseSnackSuccess={handleCloseSnackSuccess}
        openSnackSuccess={openSnackSuccess}
        serverMessage={serverMessage}
      />
    </ThemeProvider>
  );
};

export default App;
