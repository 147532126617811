import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import UpdatePassword from 'src/views/auth/UpdatePassword';
import PrivateRoute from 'src/utils/PrivateRoute';
import Clientes from 'src/views/clientes/config';
import Ordenes from 'src/views/clientes/Ordenes';
import ClienteOT from 'src/views/clientes/ClienteOT';
import Usuarios from 'src/views/config/Usuarios';
import CargaDocumentos from 'src/views/clientes/CargaDocumentos';
import SolicitudesOT from 'src/views/clientes/SolicitudesOT';
import Reportes from 'src/views/Reportes';
import CargaMasiva from './views/clientes/CargaMasiva';
import Plantilla from './views/Evaluaciones/Plantilla';
import Evaluacion from './views/Evaluaciones/Evaluacion';

const routes = (isAuth, setIsAuth, nombreUsuario, setNombreUsuario,
  rolUsuario, setRolUsuario, setOpenBackDrop, comboRoles, setComboRoles,
  setServerError, setOpenSnackError, setOpenSnackSuccess, setServerMessage,
  comboEstados, comboMunicipios, setComboMunicipios, mapaMunicipios,
  setMapaMunicipios, comboTipoClientes, setComboTipoClientes, setComboOrdenEstatus,
  comboOrdenEstatus, setComboHiperliga, comboHiperliga, setComboEstados,
  resetPassword, setResetPassword, nombreCliente, setNombreCliente, idCliente,
  setIdCliente, tipoCliente, setTipoCliente, comboLabelStatus, setComboLabelStatus) => [
  {
    path: 'app',
    element: <PrivateRoute
      component={DashboardLayout}
      nombreUsuario={nombreUsuario}
      setNombreUsuario={setNombreUsuario}
      setRolUsuario={setRolUsuario}
      rolUsuario={rolUsuario}
      setIsAuth={setIsAuth}
      isAuth={isAuth}
      setComboEstados={setComboEstados}
      comboEstados={comboEstados}
      comboOrdenEstatus={comboOrdenEstatus}
      setComboOrdenEstatus={setComboOrdenEstatus}
      comboHiperliga={comboHiperliga}
      setComboHiperliga={setComboHiperliga}
      resetPassword={resetPassword}
      nombreCliente={nombreCliente}
      setNombreCliente={setNombreCliente}
    />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardView
          setOpenBackDrop={setOpenBackDrop}
          rolUsuario={rolUsuario}
        />
      },
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: 'config/usuarios',
        element: <PrivateRoute
          component={Usuarios}
          setOpenBackDrop={setOpenBackDrop}
          comboRoles={comboRoles}
          setComboRoles={setComboRoles}
          isAuth={isAuth}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          nombreUsuario={nombreUsuario}
          rolUsuario={rolUsuario}
        />
      },
      {
        path: 'altaCliente',
        element: <PrivateRoute
          component={Clientes}
          setOpenBackDrop={setOpenBackDrop}
          isAuth={isAuth}
          comboEstados={comboEstados}
          comboMunicipios={comboMunicipios}
          setComboMunicipios={setComboMunicipios}
          mapaMunicipios={mapaMunicipios}
          setMapaMunicipios={setMapaMunicipios}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          comboTipoClientes={comboTipoClientes}
          setComboTipoClientes={setComboTipoClientes}
        />
      },
      {
        path: 'ordenes',
        element: <PrivateRoute
          component={Ordenes}
          isAuth={isAuth}
          setOpenBackDrop={setOpenBackDrop}
          comboEstados={comboEstados}
          comboMunicipios={comboMunicipios}
          setComboMunicipios={setComboMunicipios}
          mapaMunicipios={mapaMunicipios}
          setMapaMunicipios={setMapaMunicipios}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          setComboOrdenEstatus={setComboOrdenEstatus}
          comboOrdenEstatus={comboOrdenEstatus}
          setComboHiperliga={setComboHiperliga}
          comboHiperliga={comboHiperliga}
          rolUsuario={rolUsuario}
          comboLabelStatus={comboLabelStatus}
          setComboLabelStatus={setComboLabelStatus}
        />
      },
      {
        path: 'clientes/ordenes',
        element: <PrivateRoute
          component={ClienteOT}
          isAuth={isAuth}
          setOpenBackDrop={setOpenBackDrop}
          comboEstados={comboEstados}
          comboMunicipios={comboMunicipios}
          setComboMunicipios={setComboMunicipios}
          mapaMunicipios={mapaMunicipios}
          setMapaMunicipios={setMapaMunicipios}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          setComboOrdenEstatus={setComboOrdenEstatus}
          comboOrdenEstatus={comboOrdenEstatus}
          setComboHiperliga={setComboHiperliga}
          comboHiperliga={comboHiperliga}
          rolUsuario={rolUsuario}
          idCliente={idCliente}
          tipoCliente={tipoCliente}
        />
      },
      {
        path: 'reportes',
        element: <PrivateRoute
          component={Reportes}
          setOpenBackDrop={setOpenBackDrop}
          isAuth={isAuth}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
        />
      },
      {
        path: 'cargaMasiva',
        element: <PrivateRoute
          component={CargaMasiva}
          setOpenBackDrop={setOpenBackDrop}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          isAuth={isAuth}
        />
      },
      {
        path: 'plantillas',
        element: <PrivateRoute
          component={Plantilla}
          setOpenBackDrop={setOpenBackDrop}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          isAuth={isAuth}
          rolUsuario={rolUsuario}
        />
      },
      {
        path: 'evaluacion',
        element: <PrivateRoute
          component={Evaluacion}
          setOpenBackDrop={setOpenBackDrop}
          setServerError={setServerError}
          setOpenSnackError={setOpenSnackError}
          setServerMessage={setServerMessage}
          setOpenSnackSuccess={setOpenSnackSuccess}
          comboOrdenEstatus={comboOrdenEstatus}
          setComboOrdenEstatus={setComboOrdenEstatus}
          isAuth={isAuth}
          rolUsuario={rolUsuario}
        />
      },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: <LoginView
          setIsAuth={setIsAuth}
          setRolUsuario={setRolUsuario}
          setNombreUsuario={setNombreUsuario}
          setOpenBackDrop={setOpenBackDrop}
          comboEstados={comboEstados}
          setComboEstados={setComboEstados}
          setServerError={setServerError}
          setOpenServerError={setOpenSnackError}
          setResetPassword={setResetPassword}
          setNombreCliente={setNombreCliente}
          setIdCliente={setIdCliente}
          setTipoCliente={setTipoCliente}
        />
      },
      {
        path: 'cargaDocumentos',
        element: <CargaDocumentos
          setServerError={setServerError}
          setServerMessage={setServerMessage}
          setOpenSnackError={setOpenSnackError}
          setOpenSnackSuccess={setOpenSnackSuccess}
        />
      },
      {
        path: 'solicitudes',
        element: <SolicitudesOT
          setOpenBackDrop={setOpenBackDrop}
          setServerError={setServerError}
          setServerMessage={setServerMessage}
          setOpenSnackError={setOpenSnackError}
          setOpenSnackSuccess={setOpenSnackSuccess}
        />
      },
      {
        path: 'passwordReset',
        element: <PrivateRoute
          component={UpdatePassword}
          isAuth={isAuth}
          setOpenBackDrop={setOpenBackDrop}
          setServerError={setServerError}
          setServerMessage={setServerMessage}
          setOpenSnackError={setOpenSnackError}
          setOpenSnackSuccess={setOpenSnackSuccess}
        />
      },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
